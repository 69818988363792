<template>
    <a-card :bordered="true">
        <a-carousel
                v-if="picDetailList.length>0"
                arrows dots-class="slick-dots"
                :after-change="onChange"
                autoplay
                :autoplaySpeed="3000"
                :slickGoTo="1"
                :nextArrow="true"
                ref="varousel"
        >
            <div
                    slot="prevArrow"
                    slot-scope="props"
                    class="custom-slick-arrow"
                    style="left: 10px;zIndex: 1"
            >
                <a-icon type="left-circle" />
            </div>
            <div slot="nextArrow" slot-scope="props" class="custom-slick-arrow" style="right: 10px">
                <a-icon type="right-circle" />
            </div>

            <div v-for="(item,index) in picDetailList" v-bind:key="index" @click="viewSpecialItem(item)"  v-bind:title="picDetailList[index].title">
                <a-row>
                    <a-col :md="8" :sm="8" class="div-border">
                        <div class="div-inner">
                            <img class="img-thumbnail" :src="picDetailList[index].fileRelaxs[0].filePath">
                        </div>
                    </a-col>
                    <a-col :md="8" :sm="8" class="div-border">
                        <div class="div-inner">
                            <img class="img-thumbnail" :src="picDetailList[index].fileRelaxs[1].filePath">
                        </div>
                    </a-col>
                    <a-col :md="8" :sm="8" class="div-border">
                        <div class="div-inner">
                            <img class="img-thumbnail" :src="picDetailList[index].fileRelaxs[2].filePath">
                        </div>
                    </a-col>
                </a-row>
            </div>

        </a-carousel>
      <a-table
        :columns="column"
        :dataSource="menuDetailList"
        :pagination="pagination"
        rowKey="id"
        @change="handleTableChange"
        :customRow="handleClickRow"
      >
      </a-table>
    </a-card>
</template>

<script>
import { getDocList,getDocDetailList } from '@/api/basic/common'

export default {
  name: 'TableList',
  components: {
  },
  data () {
    return {
      pagination: {
        total: 0,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '15', '20'],
        showTotal: total => `共有 ${total} 条数据`
      },
      nowPage: 1,
      viewTab: true,
      // create model
      visible: false,
      confirmLoading: false,
      mdl: null,
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      menuParam: {
        menu: '资料类型'
      },
      searchParam: {
        title: '',
        type: 0
      },
      // 接口返回数据
      menuList: [],
      menuSelect: '1',
      menuDetailList: [],
      picDetailList : [],
      // table
      column: [
        {
          title: '#',
          dataIndex: 'index',
          key: 'index',
          width: '10%'
        },
        {
          title: '资料标题',
          dataIndex: 'title',
          key: 'name',
          width: '70%',
          scopedSlots: { customRender: 'name' }
        },
        {
          title: '资料类型',
          dataIndex: 'typeName',
          key: 'typeName',
          width: '20%',
          scopedSlots: { customRender: 'typeName' }
        }
      ]
    }
  },
  created () {

    if(this.$route.query.id!=null)
    {
      this.searchParam.type = this.$route.query.id
    }
    console.log('getPicList')
    this.getPicList()
    this.getList()
  },
  computed: {},
  methods: {
    // 接口请求
      getPicList () {
          getDocDetailList({type:8}).then(response => {
              for (var i = 0;i<response.data.list.length;i++) {
                  response.data.list[i].index = i + 1
              }
              this.picDetailList = response.data.list
          })
      },
    getList () {
      getDocList(this.searchParam).then(response => {
        for (var i = 0;i<response.data.list.length;i++) {
          response.data.list[i].index = i + 1
        }
        this.menuDetailList = response.data.list
      })
    },
    handleClickRow(record){
      return {
        on: {
          click: () => {
            this.viewItem (record)
          }
        }
      }
    },
    viewItem (record) {
      this.$router.push({
        path: '/base/doc/view',
        query: {
          id: record.id
        }
      })
    },
      viewSpecialItem (record) {
          console.log(record)
          this.$router.push({
              path: '/base/doc/special',
              query: {
                  id: record.id
              }
          })
      },
    tableChange (value, col, i) {
      this.menuDetailList[i + (this.nowPage - 1) * this.pagination.pageSize][col] = value
    },
    onSearch (value) {
      this.searchParam.title = value
      this.getList()
    },
    handleTableChange (pagination) {
      this.nowPage = pagination.current
    },
    onChange(a, b, c) {
      console.log(a, b, c);
    },
    showTab (v) {
      this.viewTab = v
    }
  }
}
</script>

<style scoped>

    /* For demo */
    .ant-carousel >>> .slick-dots {
        height: 10px;
	    bottom: 10px;
    }
    .ant-carousel >>> .slick-slide img {
        display: block;
        margin: auto;
        max-width: 100%;
    }

    .ant-carousel >>> .slick-dots li {
        color: #456ae2;
    }
    .ant-carousel >>> .slick-dots li button {
        height: 10px;
        border-radius: 5px;
        background-color: lightseagreen;
    }

    .ant-carousel >>> .slick-dots li.slick-active {
    	width: 36px;
    }
    .ant-carousel >>> .slick-dots li.slick-active button {
    	background-color: #b81414;
        opacity: 0.9;
    }

    /* For demo */
    .ant-carousel >>> .slick-slide {
        text-align: center;
        background: #c1cce2;
        overflow: hidden;
    }
    .ant-carousel >>> .custom-slick-arrow {
        width: 25px;
        height: 25px;
        font-size: 25px;
        color: #003bff;
        background-color: rgba(31, 45, 61, 0.11);
        opacity: 0.3;
    }
    .ant-carousel >>> .custom-slick-arrow:before {
        display: none;
    }
    .ant-carousel >>> .custom-slick-arrow:hover {
        opacity: 0.9;
    }
    .ant-carousel >>> .slick-slide h3 {
        color: #fff;
    }

    .div-border{
    /*因为每行显示三个，所以设置宽度为33%，剩下的1%作为间距*/
        width: 33%;
        height: 0;
    /*比例为3:1，根据宽度可以计算出高度为11%*/
        padding-top: 22%;
        margin-right: 0.5%;
        margin-bottom: -0.5%;
        box-sizing: border-box;
        border: 1px solid #999999;
        position: relative;
    }
    .div-border:nth-child(3n){
        margin-right: 0;
    }
    .div-inner{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        /*background: url('./images/testpic01.jpg') center center no-repeat;*/
        background-size: cover;
        display: flex;
        flex-direction: column-reverse;
    }
    .img-thumbnail{
        /*width: 36vw;*/
        /*height: 24vw;*/
        margin: 0;
        padding: 2 2px;
        font-size: 14px;
        height:100%;
        width:100%;
        line-height: 2;
        color: #333;
        background-color: rgba(255, 255, 255, 0.8);
    }

</style>

